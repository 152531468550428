import React, { useEffect, useRef, useState } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";

import { Avatar } from "@mui/material";
import { IconButton, Stack, Whisper } from "rsuite";

import AvatarDropdown from "./AvatarDropdown";
import NotificationsDropdown from "./NotificationsDropdown";
import { useAppSelector } from "../../hooks";
import { selectAuth } from "../../features/authSlice";

function Navbar() {
	const { user } = useAppSelector(selectAuth);
	const trigger = useRef(null);

	useEffect(() => {
		console.log("component rendered");
	}, [user]);

	return (
		<Stack className="header" spacing={8} justifyContent="flex-end">
			<Stack spacing={8}>
				<Whisper
					placement="bottomEnd"
					trigger="click"
					ref={trigger}
					speaker={NotificationsDropdown}
				>
					<IconButton
						icon={<IoMdNotificationsOutline size={22} />}
						style={{ right: 2 }}
					/>
				</Whisper>

				<Whisper
					placement="bottomEnd"
					trigger="click"
					ref={trigger}
					speaker={AvatarDropdown}
				>
					<Avatar
						sx={{
							width: 30,
							height: 30,
							fontSize: 17,
							marginRight: 4,
							bgcolor: "var(--cui-primary)",
						}}
					>
						JL
					</Avatar>
				</Whisper>
			</Stack>
		</Stack>
	);
}

export default Navbar;
