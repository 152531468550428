import React, { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { verifyAuth } from "../api/api/authApi";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectAuth, initiateFromToken, logout } from "../features/authSlice";

import AppLoader from "../components/AppLoader";

function PersistLogin() {
	const { isAuthenticated } = useAppSelector(selectAuth);
	const [isLoading, setIsLoading] = useState(true);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [persist, setPersist] = useState(
		localStorage.getItem("persist") || false
	);

	useEffect(() => {
		const token = localStorage.getItem("access-token");

		if (token && !isAuthenticated) {
			const authenticateUser = async () => {
				const res = await verifyAuth();
				console.log(res);

				if (res?.success) {
					dispatch(initiateFromToken());
					setIsLoading(false);
				} else {
					dispatch(logout());
					navigate("/", { replace: true });
				}
			};

			authenticateUser();
		} else {
			setIsLoading(false);
		}
	}, [isAuthenticated]);

	return isLoading ? (
		<div className="app_init-loader">
			<AppLoader />
		</div>
	) : (
		<Outlet />
	);
}

export default PersistLogin;
