import React from "react";
import ContentLayout from "../../components/layouts/ContentLayout";
import {
	Group,
	Button,
	Stack,
	TextInput,
	Box,
	ButtonGroup,
} from "@mantine/core";
import DataPanel from "../../components/DataPanel";
import { Avatar } from "@mui/material";
import AppButton from "../../components/buttons/AppButton";

function Account() {
	return (
		<ContentLayout heading="Account">
			<Stack gap={30}>
				<DataPanel title="Personal Information">
					<Box maw={"80%"}>
						<Stack>
							<Group grow>
								<TextInput label="First Name" />
								<TextInput label="Last Name" />
							</Group>

							<div className="avatar-group">
								<span className="text-sm font-semibold">Photo</span>

								<div className="flex gap-x-4 mt-2 item-center">
									<Avatar />
									<Group>
										<Button variant="default">Change</Button>
										<Button variant="transparent" color="#000">
											Remove
										</Button>
									</Group>
								</div>
							</div>

							<Group grow>
								<TextInput label="Email Address" />
								<TextInput label="Contact Number" />
							</Group>
							{/* <TextInput label="Role" /> */}
						</Stack>
					</Box>
					<Group justify="flex-end" className="mt-8">
						<AppButton label="Save Changes" />
					</Group>
				</DataPanel>

				<DataPanel style={{ color: "#d1242f" }} title="Delete Account">
					<Button
						bg="#eaeef2b3"
						style={{ borderColor: "#d0d7de", color: "#d1242f80" }}
					>
						Delete My Account
					</Button>
				</DataPanel>
			</Stack>
		</ContentLayout>
	);
}

export default Account;
