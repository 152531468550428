import React from "react";
import { Link } from "react-router-dom";
import { Popover, Stack, Badge, List, Button } from "rsuite";

const NotificationsDropdown = (
	{ onClose, left, top, className }: any,
	ref: any
) => {
	const notifications = [
		[
			"7 hours ago",
			"The charts of the dashboard have been fully upgraded and are more visually pleasing.",
		],
		[
			"13 hours ago",
			"The function of virtualizing large lists has been added, and the style of the list can be customized as required.",
		],
		["2 days ago", "Upgraded React 18 and Webpack 5."],
		[
			"3 days ago",
			"Upgraded React Suite 5 to support TypeScript, which is more concise and efficient.",
		],
	];

	return (
		<Popover
			ref={ref}
			className={className}
			style={{ left: left, top: top, width: 300 }}
			title="Last updates"
		>
			<List>
				{notifications.map((item, index) => {
					const [time, content] = item;
					return (
						<List.Item key={index}>
							<Stack spacing={4}>
								<Badge />{" "}
								<span style={{ color: "#57606a" }}>{time}</span>
							</Stack>

							<p>{content}</p>
						</List.Item>
					);
				})}
			</List>
			<div style={{ textAlign: "center", marginTop: 20 }}>
				<Link to="/view-notifications">
					<Button onClick={onClose}>More notifications</Button>
				</Link>
			</div>
		</Popover>
	);
};

export default NotificationsDropdown;
