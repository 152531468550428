import React from "react";
import { Panel, Divider, Steps } from "rsuite";
import PageHeader from "../../components/PageHeader";
import { CRow, CCol, CWidgetStatsC } from "@coreui/react";
import { GoPeople } from "react-icons/go";
import ContentLayout from "../../components/layouts/ContentLayout";
import { Group } from "@mantine/core";
import DataPanel from "../../components/DataPanel";
import Onboarding from "../../components/onboarding";

// Marketing Metrics:
// Menu views, likes, reviews, and share
// promotion results
// ad results

// Customer Satisfaction Metrics:
// ratings
// feedback
// dish Reviews

const styles = {
	width: "200px",
	display: "inline-table",
	verticalAlign: "top",
	borderRight: "2px solid #e5e5ea",
};

function Dashboard() {
	return (
		<ContentLayout heading="">
			<Onboarding />
			{/* <DataPanel title="Reviews"></DataPanel> */}
			{/* <DataPanel title="Setup Guide">
				<Group
					style={{
						height: 200,
					}}
				>
					<Steps current={0} vertical style={styles} small>
						<Steps.Item title="Set up account" />
						<Steps.Item title="Create store" />
						<Steps.Item title="Verify your email" />
					</Steps>

					<Panel bodyFill style={{ height: "100%", flex: 1 }}>
						dsadsaasa
					</Panel>
				</Group>
			</DataPanel> */}
			{/* <Panel shaded>
				<CRow>
					<CCol xs={6}>
						<CWidgetStatsC
							className="mb-3"
							// icon={<CIcon icon={cilChartPie} height={36} />}
							icon={<GoPeople size={40} />}
							progress={{ color: "success", value: 75 }}
							title="Visitors"
							value="403"
						/>
					</CCol>
					<CCol xs={6}>
						<CWidgetStatsC
							className="mb-3"
							// icon={<CIcon icon={cilChartPie} height={36} />}
							color="primary"
							inverse
							progress={{ value: 75 }}
							title="Reviews"
							value="107"
						/>
					</CCol>
				</CRow>
			</Panel> */}
		</ContentLayout>
	);
}

export default Dashboard;
