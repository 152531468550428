interface Props {
	title?: string | undefined;
}

const PageHeader = ({ title }: Props) => {
	return (
		<header>
			<h5>{title}</h5>
		</header>
	);
};

export default PageHeader;
