import { apiInstance } from "..";

type PasswordUpdatePayload = {
	currentPassword: string;
	password: string;
	confirmPassword: string;
};

export const updatePassword = async (payload: PasswordUpdatePayload) => {
	try {
		const { data } = await apiInstance.patch(
			"/users/change-password",
			payload
		);
		return data;
	} catch (err: any) {
		console.log(err);

		if (err.response) {
			return err.response.data;
		}
	}
};
