import { apiInstance, authInstance } from "..";
import { AxiosResponse } from "axios";
import axios from "axios";

type SignupPayload = {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
};

type LoginPayload = {
	email: string;
	password: string;
};

export const login = async (payload: LoginPayload) => {
	try {
		const { data } = await authInstance.post(
			"/api/v1//merchant/auth/login",
			payload
		);
		return data;
	} catch (error: any) {
		console.log(error);

		if (error.response) {
			return error.response.data;
		}
	}
};

export const signup = async (payload: SignupPayload) => {
	try {
		const { data } = await authInstance.post(
			"/api/v1/merchant/auth/become-partner",
			payload
		);
		return data;
	} catch (err: any) {
		console.log(err);

		if (err.response) {
			return err.response.data;
		}
	}
};

export const verifyAuth = async () => {
	try {
		const { data } = await apiInstance.get("/merchant/auth/verify");

		if (data.success) {
			return data;
		}
	} catch (err: any) {
		console.log(err);
		if (err.response) {
			return err.response.data;
		}
	}
};

export const refreshToken = async () => {
	try {
		const res = await authInstance.get("/api/v1/auth/refresh");
	} catch (err) {
		console.log(err);
	}
};

export const forgotPassword = async (email: string) => {
	try {
		const url = "/api/v1/merchant/auth/forgot-password";
		const response = await authInstance.post(url, { email: email });
		console.log("response", response);

		return response;
	} catch (err: any) {
		return err.response;
	}
};
