import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

function AuthRedirect() {
	const token = localStorage.getItem("access-token");
	let location = useLocation();

	return token ? (
		<Navigate to="/dashboard" state={{ from: location }} replace />
	) : (
		<Outlet />
	);
}

export default AuthRedirect;
