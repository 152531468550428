import React from "react";
import { Stack } from "rsuite";
import AppButton from "../../components/buttons/AppButton";
import {
	CDropdown,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
} from "@coreui/react";
import { Group } from "@mantine/core";

const Header = () => {
	return (
		<Stack justifyContent="space-between">
			<span>Pizza Pasta</span>

			<Stack spacing={8}>
				<Group>
					<CDropdown>
						<CDropdownToggle>Action</CDropdownToggle>
						<CDropdownMenu>
							<CDropdownItem as="button">Delete</CDropdownItem>
						</CDropdownMenu>
					</CDropdown>

					<AppButton label="Save" />
				</Group>
			</Stack>
		</Stack>
	);
};

export default Header;
