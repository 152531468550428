import React from "react";
import moment from "moment";
import { Select } from "@mantine/core";
import AppButton from "../../../components/buttons/AppButton";
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";

const StoreHours = () => {
	return (
		<div className="store_hours">
			<ul className="store_hours-list">
				{moment.weekdays().map((item, i) => {
					return (
						<li key={i.toString()} className="weekday-item">
							<label className="checkbox-label" htmlFor={item}>
								<input id={item} type="checkbox" />
								{item}
							</label>

							<div className="hour_selectors-group">
								<div className="selects">
									<Select
										className="hour-selection-dd"
										placeholder="10:00 AM"
									/>{" "}
									-{" "}
									<Select
										className="hour-selection-dd"
										placeholder="10:00 AM"
									/>
								</div>
								<FaCirclePlus size={22} className="add-icon" />
								<FaCircleMinus size={22} className="minus-icon" />
							</div>
						</li>
					);
				})}
			</ul>

			<div style={{ marginTop: 50 }}>
				<AppButton label="Save shifts" />
			</div>
		</div>
	);
};

export default StoreHours;
