import React from "react";
import { Stack } from "@mantine/core";
import ContentLayout from "../../components/layouts/ContentLayout";
import DataPanel from "../../components/DataPanel";

// Groups
import ChangePassword from "./groups/ChangePassword";
import TwoFactorAuth from "./groups/TwoFactorAuth";

function AccountSecurity() {
	return (
		<ContentLayout heading="Account Security">
			<Stack gap={30}>
				<DataPanel title="Change Password">
					<ChangePassword />
				</DataPanel>

				<DataPanel title="Two-Factor Authentication">
					<TwoFactorAuth />
				</DataPanel>
			</Stack>
		</ContentLayout>
	);
}

export default AccountSecurity;
