import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode, JwtPayload } from "jwt-decode";

export interface IUser {
	id: number;
	email: string;
	role: number | null;
	token: string;
}

export interface AuthInitialState {
	user: null | IUser;
	isAuthenticated: boolean;
	persistLogin: boolean;
}

const initialState: AuthInitialState = {
	user: null,
	isAuthenticated: false,
	persistLogin: false,
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setCredentials: (state, action) => {
			const { payload } = action;
			const decoded: any = jwtDecode<JwtPayload>(payload.accessToken);
			const persistUserlogin = localStorage.getItem("persist");

			console.log("token decoded", decoded);

			const user = {
				id: decoded.id,
				email: decoded.email,
				token: payload.accessToken,
				role: payload.role,
			};

			localStorage.setItem("user", JSON.stringify(user));
			localStorage.setItem("access-token", payload.accessToken);
			localStorage.setItem("refresh-token", payload.refreshToken);

			if (persistUserlogin) {
				state.persistLogin = JSON.parse(persistUserlogin);
			}

			state.user = user;
			state.isAuthenticated = decoded.isAuthenticated;
		},

		logout: (state) => {
			state.user = null;
			state.isAuthenticated = false;
			localStorage.clear();
		},

		initiateFromToken: (state) => {
			const accessToken: any = localStorage.getItem("access-token");
			const decoded: any = jwtDecode<JwtPayload>(accessToken);

			const user = {
				id: decoded.id,
				email: decoded.email,
				token: accessToken,
				role: decoded.role,
			};

			state.user = user;
			state.isAuthenticated = decoded.isAuthenticated;
		},
	},
});

export const { setCredentials, logout, initiateFromToken } = authSlice.actions;

export const selectAuth = (state: any) => state.auth;
export const selectUser = (state: any) => state.auth.user;

export default authSlice.reducer;
