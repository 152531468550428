import React from "react";
import {
	Box,
	Group,
	NumberInput,
	Select,
	Stack,
	TextInput,
} from "@mantine/core";
import { Form } from "rsuite";

import STATES_JSON from "../../states.json";

type StatesJSON = {
	name: string;
	abbreviation: string;
};

function StoreInfo({ state, setState }: any) {
	const STATES = STATES_JSON.map(
		({ name, abbreviation }: StatesJSON) => abbreviation
	);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const handleStateSelection = (value: string | null) => {
		setState({ ...state, state: value });
	};

	const handleNumberOfLocations = (value: string | number) => {
		setState({ ...state, numOfLocations: value });
	};

	return (
		<Form>
			<Box maw={450} mx="auto">
				<Stack>
					<TextInput
						label="Store Name"
						name="storeName"
						value={state.store_name}
						onChange={handleChange}
					/>
					<TextInput
						label="Address 1"
						name="address1"
						value={state.address_1}
						onChange={handleChange}
					/>
					<TextInput
						label="Address 2"
						value={state.address_2}
						name="address2"
						onChange={handleChange}
					/>
					<Group grow>
						<TextInput
							label="City"
							name="city"
							value={state.city}
							onChange={handleChange}
						/>
						<Select
							label="State"
							data={STATES}
							name="state"
							value={state.state}
							onChange={handleStateSelection}
						/>
						<TextInput
							label="Zip"
							value={state.zip}
							onChange={handleChange}
							name="zip"
						/>
					</Group>
					<TextInput
						label="Store Phone Number"
						value={state.phone}
						name="phoneNumber"
						onChange={handleChange}
					/>
					<Group grow>
						<Select label="Type of cuisine" />
						<NumberInput
							label="Number of Locations"
							name="numOfLocations"
							value={state.num_of_locations}
							onChange={handleNumberOfLocations}
						/>
					</Group>
					<TextInput
						label="Website"
						value={state.website}
						name="website"
						onChange={handleChange}
					/>
					<TextInput
						label="Intagram"
						value={state.instagram}
						onChange={handleChange}
						name="instagram"
					/>
					<TextInput
						label="Facebook"
						value={state.facebook}
						onChange={handleChange}
						name="facebook"
					/>
				</Stack>
			</Box>
		</Form>
	);
}

export default StoreInfo;
