import React, { useState } from "react";
import { Uploader, Drawer, ButtonToolbar, Button } from "rsuite";
import AppButton from "../../components/buttons/AppButton";
import { Group, Text, Select, Stack, TextInput, Textarea } from "@mantine/core";
import { FaCloudUploadAlt } from "react-icons/fa";
import { createNewItem } from "../../api/api/menuApi";

type BackdropState = boolean | "static" | undefined;

function CreateNew() {
	const [open, setOpen] = useState(false);
	const [backdrop, setBackdrop] = useState<BackdropState>(true);

	const [item, setItem] = useState({
		name: "",
		category: "",
		description: "",
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setItem({ ...item, [name]: value });
	};

	const handleCreate = async () => {
		try {
			const res = await createNewItem(item);
			console.log("New Item created response", res);
		} catch (err) {
			console.log(err);
		}
		// Save new dish
		console.log("New dish created");
		// setOpen(false)
	};

	return (
		<>
			<ButtonToolbar>
				<AppButton onClick={() => setOpen(true)} label="Create New" />
			</ButtonToolbar>
			<Drawer
				size="28rem"
				backdrop={backdrop}
				open={open}
				onClose={() => setOpen(false)}
			>
				<Drawer.Header>
					<Drawer.Title>New Dish</Drawer.Title>
					<Drawer.Actions>
						<Group>
							<Button onClick={() => setOpen(false)}>Cancel</Button>
							<AppButton label="Create" onClick={handleCreate} />
						</Group>
					</Drawer.Actions>
				</Drawer.Header>
				<Drawer.Body>
					<Stack>
						<TextInput
							label="Dish Name"
							placeholder="What's your new dish called?"
							name="name"
							value={item.name}
							onChange={handleChange}
						/>
						<Select label="Category" value={item.category} />
						<Textarea
							minRows={5}
							rows={5}
							autosize
							name="description"
							label="Description"
							value={item.description}
							onChange={handleChange}
							placeholder="Write a description for your dish on its ingredients"
						/>

						<Uploader
							action="//jsonplaceholder.typicode.com/posts/"
							draggable
						>
							<div
								style={{
									height: 150,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<FaCloudUploadAlt size={38} />
								<Text size="sm" c="dimmed" mt={6}>
									Click or Drag a picture of your new dish
								</Text>
							</div>
						</Uploader>
					</Stack>
				</Drawer.Body>
			</Drawer>
		</>
	);
}

export default CreateNew;
