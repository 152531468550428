import React from "react";
import { Button, Group, Stack } from "@mantine/core";

function TwoFactorAuth() {
	return (
		<Stack>
			<span>
				Two-factor authentication adds an additional layer of security to
				your account by requiring more than just a password to sign in.
				Learn more about two-factor authentication.
			</span>

			<Group justify="flex-end">
				<Button bg="#F4F4F4" style={{ color: "#1B1212" }}>
					Setup
				</Button>
			</Group>
		</Stack>
	);
}

export default TwoFactorAuth;
