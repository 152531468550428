import { createTheme } from "@mantine/core";

export const theme = createTheme({
	// Mantine theme override here

	// theme.breakpoints are used in all responsive Mantine components.
	// Breakpoints are expected to be set in em units.
	// You can configure these values with MantineProvider:
	breakpoints: {
		xs: "30em",
		sm: "48em",
		md: "64em",
		lg: "74em",
		xl: "90em",
	},

	headings: {
		// properties for all headings
		fontWeight: "400",
	},
});
