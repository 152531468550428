import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaBell } from "react-icons/fa";
import { Dropdown, Popover } from "rsuite";
import { logout, selectAuth, selectUser } from "../../features/authSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";

import { IoPerson } from "react-icons/io5";
import { PiSignOutBold } from "react-icons/pi";
import { FaGear } from "react-icons/fa6";

const AvatarDropdown = ({ onClose, left, top, className }: any, ref: any) => {
	const dispatch = useAppDispatch();

	const handleSelect = (eventKey: any) => {
		console.log(eventKey);

		if (eventKey === "logout") {
			console.log("logout");
			dispatch(logout());
		}

		onClose();
	};

	return (
		<Popover ref={ref} className={className} style={{ left, top }} full>
			<Dropdown.Menu onSelect={handleSelect}>
				<Dropdown.Item panel style={{ padding: "4px 10px", width: 150 }}>
					{/* <p>Signed in as</p> */}
					{/* <strong>{user?.username}</strong> */}
					<span className="user-dd-header-text">Signed in as</span> <br />
					<span className="header-text-helper">Owner</span>
				</Dropdown.Item>
				<Dropdown.Separator />
				<Dropdown.Item
					style={{ display: "flex", alignItems: "center", gap: 8 }}
				>
					<Link className="user-dd-item account" to="/account">
						<IoPerson size={17} />
						Account
					</Link>
				</Dropdown.Item>

				<Dropdown.Item
					style={{ display: "flex", alignItems: "center", gap: 8 }}
				>
					<Link
						className="user-dd-item notifications"
						to="/view-notifications"
					>
						<FaBell size={17} />
						Notifications
					</Link>
				</Dropdown.Item>

				{/* <Dropdown.Item
					style={{ display: "flex", alignItems: "center", gap: 8 }}
				>
					<Link className="user-dd-item favorites" to="/favorites">
						<IoMdHeart size={17} />
						My favorites
					</Link>
				</Dropdown.Item> */}
				{/* <Dropdown.Separator /> */}
				<Dropdown.Item
					style={{
						display: "flex",
						alignItems: "center",
						gap: 8,
						width: "100%",
					}}
				>
					<Link className="user-dd-item settings" to="/settings">
						<FaGear size={17} />
						Settings
					</Link>
				</Dropdown.Item>
				<Dropdown.Item
					eventKey="logout"
					style={{
						display: "flex",
						alignItems: "center",
						gap: 8,
						width: "100%",
					}}
				>
					<span className="user-dd-item logout">
						<PiSignOutBold size={17} />
						Sign out
					</span>
				</Dropdown.Item>
				{/* <Dropdown.Item
					icon={<FaGear />}
					href="https://rsuitejs.com"
					target="_blank"
					as="a"
				>
					Help{" "}
				</Dropdown.Item> */}
			</Dropdown.Menu>
		</Popover>
	);
};

export default AvatarDropdown;
