import React from "react";
import { Panel } from "rsuite";

interface Props {
	title: string;
	children?: React.ReactNode;
	style?: React.CSSProperties;
}

function DataPanel({ title, children, ...props }: Props) {
	return (
		<Panel header={title} {...props} className="panel-item" shaded>
			{children}
		</Panel>
	);
}

export default DataPanel;
