import React, { Suspense } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import AppSidebar from "../appSidebar";
import AppLoader from "../AppLoader";
import Navbar from "../navbar/AppNavbar";
import { useAppSelector } from "../../hooks";
import { selectAuth } from "../../features/authSlice";

function Layout() {
	const { user } = useAppSelector(selectAuth);
	let location = useLocation();

	if (!user) {
		return <Navigate to="/" state={{ from: location }} replace />;
	}

	return (
		<Suspense fallback={<AppLoader />}>
			<div className="app__layout">
				<AppSidebar />

				<div style={{ width: "100%" }}>
					<Navbar />
					<main id="main">
						<Outlet />
					</main>
				</div>
			</div>
		</Suspense>
	);
}

export default Layout;
