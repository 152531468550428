import React from "react";
import { Link } from "react-router-dom";
import { Button, Flex, PasswordInput, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import AppButton from "../../../components/buttons/AppButton";
import { FaArrowRightLong } from "react-icons/fa6";

export interface IFormState {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
}

type Props = {
	state: IFormState;
	loading: boolean;
	handleSubmit: (values: IFormState) => void;
};

const styles = {
	label: {
		// fontSize: 15,
		fontWeight: 400,
	},
};

function SignupForm({ state, loading, handleSubmit }: Props) {
	const form = useForm({
		initialValues: state,
		validate: {
			firstName: (value) => (value === "" ? "Field required" : null),
			lastName: (value) => (value === "" ? "Field required" : null),
			email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
			// Password validation ideas
			// Include uppercase and lowercase letter, include number,
			// include a special character: #.-?!@$%^&*
			password: (value) =>
				value === ""
					? "Field required"
					: value.length < 8
					? "Minimum 8 characters"
					: null,
		},
	});

	return (
		<form onSubmit={form.onSubmit(handleSubmit)} className="signup__form">
			<div className="input_field-container">
				<Flex gap="xs" justify="center" align="center">
					<TextInput
						styles={styles}
						label="First Name"
						name="firstName"
						placeholder="First Name"
						{...form.getInputProps("firstName")}
					/>
					<TextInput
						label="Last Name"
						name="lastName"
						value={state.lastName}
						styles={styles}
						placeholder="Last Name"
						{...form.getInputProps("lastName")}
					/>
				</Flex>

				<TextInput
					value={state.email}
					name="email"
					label="Email"
					placeholder="email@business.com"
					styles={styles}
					{...form.getInputProps("email")}
				/>
				<PasswordInput
					label="Password"
					name="password"
					value={state.password}
					placeholder="Password"
					styles={styles}
					{...form.getInputProps("password")}
				/>
			</div>

			<div className="terms_of_agreement_wrapper">
				<Text span className="terms_of_agreement_text">
					By clicking "Sign up", you agree to our <Link to="">terms</Link>{" "}
					and acknowledge our <Link to="">Privacy Policy</Link>
				</Text>
			</div>

			<AppButton
				variant="filled"
				loading={loading}
				mt="md"
				fullWidth
				type="submit"
				label="Continue"
				rightSection={<FaArrowRightLong />}
			/>

			<Text className="login_link_text">
				Already have an account? <Link to="/login">Login</Link>
			</Text>
		</form>
	);
}

export default SignupForm;
