import React from "react";
import PageHeader from "../PageHeader";
import { Panel } from "rsuite";

type Props = {
	heading?: string;
	children: React.ReactNode;
};

function ContentLayout({ heading, children }: Props) {
	return (
		<Panel
			header={<PageHeader title={heading} />}
			className="content-container"
		>
			{children}
		</Panel>
	);
}

export default ContentLayout;
