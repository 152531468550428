import React, { useEffect } from "react";
import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
} from "react-router-dom";

import { MantineProvider } from "@mantine/core";
import { theme } from "./themes";

import RequireAuth from "./routes/RequireAuth";
import Login from "./pages/auth/login/Login";
import Signup from "./pages/auth/signup";
import Dashboard from "./pages/dashboard/Dashboard";
import AppLayout from "./components/layouts/AppLayout";
import NotFound from "./pages/NotFound";
import PersistLogin from "./routes/PersistLogin";
import { useAppDispatch } from "./hooks";
import { initiateFromToken } from "./features/authSlice";
import AuthRedirect from "./routes/AuthRedirect";
import MenuManager from "./pages/menuManager";
import ManageUsers from "./pages/manageUsers";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Stores from "./pages/stores";
import DishEdit from "./pages/DishEdit.tsx";
import Notifications from "./pages/notifications/Notifications";
import AccountSecurity from "./pages/accountSecurity";
import Account from "./pages/account/Account";
import NotificationSettings from "./pages/notificationSettings";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" errorElement={<NotFound />}>
			{/* Public routes */}
			<Route element={<AuthRedirect />}>
				<Route index element={<Login />} />
				<Route path="login" element={<Login />} />
				<Route path="become-a-partner" element={<Signup />} />
				<Route path="forgot-password" element={<ForgotPassword />} />
			</Route>

			{/* Protected */}
			<Route element={<PersistLogin />}>
				<Route element={<RequireAuth />}>
					{/* <Route path='onboarding' element={<Onboarding} */}
					<Route element={<AppLayout />}>
						<Route path="dashboard" element={<Dashboard />} />
						<Route path="menu-manager" element={<MenuManager />} />
						<Route path="dish/:id" element={<DishEdit />} />
						<Route path="store" element={<Stores />} />
						<Route path="users" element={<ManageUsers />} />
						<Route
							path="view-notifications"
							element={<Notifications />}
						/>

						<Route path="account" element={<Account />} />
						<Route
							path="settings/notifications"
							element={<NotificationSettings />}
						/>
						<Route
							path="settings/account-security"
							element={<AccountSecurity />}
						/>

						{/* <Route path="/add-new-dish" element={<AddPicure />} />
					<Route path="stores" element={<Stores />} />
					<Route path="/account" element={<Account />} /> */}
					</Route>
				</Route>
			</Route>
		</Route>
	)
);

function App() {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const token = localStorage.getItem("access-token");

		if (token) {
			dispatch(initiateFromToken());
		}
	}, []);

	return (
		<MantineProvider theme={theme}>
			<RouterProvider router={router} />
		</MantineProvider>
	);
}

export default App;
