import React, { useState } from "react";
import { Steps, Divider, Stack, IconButton } from "rsuite";

import PageNextIcon from "@rsuite/icons/PageNext";
import PagePreviousIcon from "@rsuite/icons/PagePrevious";

import StoreInfo from "./StoreInformation";
import Completed from "./Completed";
import DataPanel from "../DataPanel";
import AppButton from "../buttons/AppButton";
import { Group } from "@mantine/core";
import { apiInstance } from "../../api";

// const forms = [PersonalInformation, StoreInfo, Completed];
const forms = [StoreInfo, Completed];

const Onboarding = () => {
	const [step, setStep] = useState(0);
	const Form = forms[step];

	const [store, setStore] = useState({
		store_name: "",
		address_1: "",
		address_2: "",
		city: "",
		state: "",
		zip: "",
		phone: "",
		cuisine_type: "",
		num_of_locations: "",
		website: "",
		instagram: "",
		facebook: "",
	});

	console.log("store data", store);

	const previousPage = () => {
		setStep(Math.max(step - 1, 0));
	};

	const nextPage = () => {
		setStep(Math.min(step + 1, 4));
	};

	const handleSubmit = async () => {
		console.log("Form Submission");
		try {
			const { data } = await apiInstance.post("/stores/create", store);
			console.log("Onboarding response", data);

			// nextPage();
		} catch (err: any) {
			if (err.response) {
				// handle error
			}
		}
	};

	return (
		<DataPanel title="Finish Setting Up Your Account">
			<Steps current={step} small>
				{/* <Steps.Item title="Personal Information" /> */}
				<Steps.Item title="Setup store" />
				<Steps.Item title="Completed" />
			</Steps>

			<Divider />
			<div className="wizard-form">
				<Form
					{...(step === 0 ? { state: store, setState: setStore } : null)}
				/>

				<Divider />

				<Group justify="flex-end">
					{step !== 0 && step !== forms.length - 1 && (
						<IconButton
							icon={<PagePreviousIcon />}
							onClick={previousPage}
						>
							Back
						</IconButton>
					)}

					{step === 0 && (
						<AppButton label="Continue" onClick={handleSubmit} />
					)}
				</Group>
			</div>
		</DataPanel>
	);
};

export default Onboarding;
