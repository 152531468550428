import React, { useEffect, useState } from "react";
import { Button, Group, PasswordInput, Stack } from "@mantine/core";
import AppButton from "../../../components/buttons/AppButton";
import { updatePassword } from "../../../api/api/usersApi";

const styles = {
	label: {
		// fontSize: 15,
		fontWeight: 400,
	},
};

function ChangePassword() {
	const [isFormComplete, setIsFormComplete] = useState(false);
	const [currentPassword, setCurrentPassword] = useState("");

	const [newPassword, setNewPassword] = useState({
		password: "",
		confirmPassword: "",
	});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		if (name === "currentPassword") {
			setCurrentPassword(value);
		} else {
			setNewPassword({ ...newPassword, [name]: value });
		}
	};

	const handlePasswordUpdate = async () => {
		if (newPassword.password !== newPassword.confirmPassword) {
			return;
		}

		try {
			// Handle password update
			const payload = { currentPassword, ...newPassword };
			const data = await updatePassword(payload);
			console.log("Change password response => ", data);
		} catch (err: any) {
			if (err.response) {
				console.log(err);
			}
		}
	};

	useEffect(() => {
		const isFormFilled =
			currentPassword === "" ||
			newPassword.password === "" ||
			newPassword.confirmPassword === "";

		if (isFormFilled) {
			setIsFormComplete(false);
		} else {
			setIsFormComplete(true);
		}
	}, [currentPassword, newPassword]);

	return (
		<Stack>
			<Stack maw={"40%"}>
				<PasswordInput
					label="Current Password"
					name="currentPassword"
					value={currentPassword}
					// placeholder=""
					styles={styles}
					onChange={handleChange}
					withAsterisk
				/>

				<PasswordInput
					label="New Password"
					name="password"
					value={newPassword.password}
					// placeholder="Password"
					styles={styles}
					withAsterisk
					onChange={handleChange}
				/>

				<PasswordInput
					label="Confirm New Password"
					name="confirmPassword"
					value={newPassword.confirmPassword}
					withAsterisk
					// placeholder="Password"
					styles={styles}
					onChange={handleChange}
				/>
			</Stack>

			<Group justify="flex-end">
				{/* <Button bg="#F4F4F4" style={{ color: "#1B1212" }}>
					Cancel
				</Button> */}
				<AppButton
					onClick={handlePasswordUpdate}
					label="Update Password"
					disabled={!isFormComplete}
				/>
			</Group>
		</Stack>
	);
}

export default ChangePassword;
