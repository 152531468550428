import { apiInstance } from "..";

type Item = {
	name: string;
	category: string;
	description: string;
};

export const createNewItem = async (payload: Item) => {
	try {
		// handle request
		const { data } = await apiInstance.post("/dishes/create", payload);
		return data;
	} catch (err) {
		console.log(err);
	}
};

export const getAllItems = async () => {
	try {
		// handle request
	} catch (err) {
		console.log(err);
	}
};

export const getOneItemById = async () => {
	try {
		// handle request
	} catch (err) {
		console.log(err);
	}
};

export const updateItem = async (id: number, payload: Item) => {
	try {
		// handle request
		const { data } = await apiInstance.patch(`/dishes/${id}`, payload);
		return data;
	} catch (err) {
		console.log(err);
	}
};

export const deleteItem = async (id: number) => {
	try {
		// handle request
		const { data } = await apiInstance.delete(`/dishes/:${id}`);
		return data;
	} catch (err) {
		console.log(err);
	}
};
