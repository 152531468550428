import React from "react";
import ContentLayout from "../../components/layouts/ContentLayout";

function index() {
	return (
		<ContentLayout heading="Notification Preferences">
			Notification preferences
		</ContentLayout>
	);
}

export default index;
