import React, { useState } from "react";
import { ButtonGroup, Panel, Stack } from "rsuite";
import PageHeader from "../../components/PageHeader";

import CreateNew from "./CreateNew";
import DishCard from "../../components/DishCard";

import DISH1 from "../../assets/dish_1.jpeg";
import DISH2 from "../../assets/dish2.jpeg";
import { Button, FileButton, Group, Text } from "@mantine/core";
import { MdOutlineFileUpload } from "react-icons/md";
import ContentLayout from "../../components/layouts/ContentLayout";

const dishes = [
	{ title: "Pot Roast", desc: "", picture: DISH1 },
	{
		title: "Shoyu Ramen",
		desc: "",
		picture: DISH2,
	},
];

const ActionButtons = () => {
	const [file, setFile] = useState<File | null>(null);
	return (
		<Stack justifyContent="space-between">
			<h5 className="heading">Menu Manager</h5>
			<Stack spacing={8}>
				<Group>
					<FileButton
						// resetRef={resetRef}
						onChange={setFile}
						accept="application/pdf"
					>
						{(props) => (
							<Button
								style={{ color: "#1B1212", backgroundColor: "#f4f4f4" }}
								rightSection={<MdOutlineFileUpload size={17} />}
								{...props}
							>
								Import Menu
							</Button>
						)}
					</FileButton>
				</Group>
				<CreateNew />

				{file && (
					<Text size="sm" ta="center" mt="sm">
						Picked file: {file.name}
					</Text>
				)}
			</Stack>
		</Stack>
	);
};

function MenuManager() {
	return (
		<Panel header={<ActionButtons />}>
			{/* <Grid>
				{dishes.map((dish) => (
					<Grid.Col span={3}>
						<DishCard
							title={dish.title}
							desc={dish.desc}
							picture={dish.picture}
						/>
					</Grid.Col>
				))}
			</Grid> */}
		</Panel>
	);
}

export default MenuManager;
